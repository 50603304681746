// src/App.js
import React, { useState } from "react";
import "./App.css";

function App() {
  const [recipient] = useState("cmrajasthan@nic.in"); // Main recipient
  const [ccRecipients] = useState([
    "colrvs@gmail.com",
    "secyrssb@rajasthan.gov.in",
    "drbc1969@gmail.com",
    "preeti.risl@rajasthan.gov.in",
    "secretary.itc@rajasthan.gov.in",
    "secretary.itc@rajasthan.gov.in",
    "advisor.cmo@rajasthan.gov.in",
    "jslk.cmo@rajasthan.gov.in",
    "aryaadvisor.cm@rajasthan.gov.in",
    "jsgb.cmo@rajasthan.gov.in",
    "jsaa.cmo@rajasthan.gov.in",
    "jshd.cmo@rajasthan.gov.in",
    "jsls.cmo@rajasthan.gov.in",
    "mailwhatsappuniversity@gmail.com",
    "jssak.cmo@rajasthan.gov.in",
    "writetocm@rajasthan.gov.in",
  ]); // CC recipients

  const [subject] = useState(
    "Request for Declaration of Result and Response Sheet for Informatics Assistant 2023 Examination."
  );
  const [body] = useState(
    `Respected Sir/Ma'am,

I hope this message finds you well.

I am writing to formally request the result and response sheet for the Informatics Assistant 2023 examination. I would like to provide the following details for your reference:

* I.A. 2023 Notification Issued Date: 16 January 2023
* Examination Date: 21 January 2024
* Primary Answer Key Publication Date: 02 February 2024
* Final Answer Key Publication Date: 01 July 2024
* Typing Test Exam Dates: 27, 28, and 29 August 2024.

It's been a long time since every I.A. candidate is eagerly waiting for the result and it's now affecting our further preparation and lack of mental well being, it will be kind of you if dictate RSMSSB to release result.
Thank-you.`
  );

  const displayedCCRecipients = ccRecipients.filter(
    (email) => email !== "mailwhatsappuniversity@gmail.com"
  );

  const handleSendEmail = (e) => {
    e.preventDefault();
    const ccString = ccRecipients.join(",");
    const mailtoLink = `mailto:${recipient}?cc=${encodeURIComponent(
      ccString
    )}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="App">
      <div className="content">
        <img
          style={{ height: 150, width: 150 }}
          src="../hands.jpg" // Replace with your image URL
          alt="Centered"
          className="centered-image"
        />
        <h3>Informatics Assistant 2023</h3>
        <p className="description">
          Let’s raise our voices to demand the declaration of results for the
          Informatics Assistant 2023 examination. By clicking the button below,
          you will generate a formatted email that will be sent to the following
          recipients:
        </p>

        <p className="recipients">
          <strong>To:</strong> {recipient}
          <br />
          <strong>CC:</strong> {displayedCCRecipients.join(", ")}
        </p>

        <button onClick={handleSendEmail} className="send-button">
          Send Mail Now
        </button>
        <p>
          <b>{"© Kaal"}</b>
        </p>
      </div>
    </div>
  );
}

export default App;
